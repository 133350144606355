.banners {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
  flex-wrap: wrap; /* Ensures the banners wrap on smaller screens */
}

.banner {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2rem;
  width: 30%;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .banners {
      flex-direction: column; /* Stack banners vertically */
      align-items: center; /* Center banners horizontally */
  }

  .banner {
      width: 80%; /* Take up more space on mobile for better readability */
      margin-bottom: 1rem; /* Add space between stacked banners */
  }
}

.banner h3 {
  margin-bottom: 1rem;
}

.banner p {
  font-size: 1rem;
}

/* Adjust font size for smaller screens */
@media (max-width: 480px) {
  .banner p {
      font-size: 0.9rem; /* Slightly smaller text on very small screens */
  }

  .banner {
      padding: 1.5rem; /* Reduce padding for smaller devices */
  }
}
