.footer {
  background-color: #333; /* Dark background color */
  color: white; /* Text color */
  padding: 2rem; /* Padding for space inside the footer */
  text-align: center; /* Center text alignment */
  width: 100%; /* Full width */
  position: relative; /* Position relative to the parent container */
  margin-bottom: 0; /* No margin at the bottom */
}

/* Responsive typography for paragraphs */
.footer p {
  margin: 0.5rem 0; /* Margin for spacing between paragraphs */
  font-size: 1rem; /* Default font size */
}

/* Strong text styling */
.footer strong {
  font-size: 1.2rem; /* Slightly larger font size for emphasis */
}

/* Add hover effect to links (if applicable) */
.footer a {
  color: #ffcc00; /* Gold color for links */
  text-decoration: none; /* Remove underline from links */
  transition: color 0.3s; /* Smooth color transition */
}

.footer a:hover {
  color: #ffd700; /* Bright gold on hover */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .footer p {
    font-size: 0.9rem; /* Slightly smaller font size on mobile */
  }

  .footer strong {
    font-size: 1.1rem; /* Slightly smaller strong text */
  }
}
