.call-action {
  text-align: center;
  margin: 2rem 0;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

.call-action h6 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.call-action .button-container {
  display: flex; /* Use flexbox for buttons */
  justify-content: center; /* Center buttons in the row */
  gap: 1rem; /* Space between buttons */
}

.call-action button {
  background-color: #007bff; /* Blue color */
  border: none;
  padding: 1rem 2rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin: 0; /* No margin for buttons */
  
  border-radius: 25px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.call-action button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Chat Now button styles with higher specificity */
.call-action .chatWithUs-button {
  background-color: #25D366; /* WhatsApp Green */
  border: none; /* No border */
  padding: 1rem 2rem; /* Padding */
  color: white; /* Text color */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  margin: 0; /* No margin for buttons */
  
  border-radius: 25px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.call-action .chatWithUs-button:hover {
  background-color: #128C7E; /* Darker green on hover */
}

.chatWithUs-button {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
}

.whatsapp-icon {
  width: 16px; /* Set width */
  height: 16px; /* Set height */
  margin-right: 8px; /* Space between icon and text */
}
