.questions {
  display: flex; /* Flexbox for horizontal layout */
  justify-content: space-around; /* Even spacing between items */
  margin: 2rem 0; /* Margin for top and bottom */
  text-align: center; /* Center text alignment */
  position: relative; /* Ensures it stays on top of the video */
  z-index: 10; /* Stays above the video */
}

.question-item {
  width: 30%; /* Width of each question item */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background for readability */
  color: white; /* White text for better visibility */
  padding: 1.5rem; /* Increased padding for better spacing */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Softer shadow for a more modern look */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.question-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.question-item img {
  width: 100%; /* Full width of the parent */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1rem; /* Space below the image */
  border-radius: 8px; /* Match the border radius of the item */
}

.question-item h4 {
  font-size: 1.4rem; /* Slightly larger font size for better readability */
  margin-bottom: 0.5rem; /* Space below the heading */
}

/* Responsive styles */
@media (max-width: 768px) {
  .questions {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center items */
  }

  .question-item {
    width: 80%; /* Wider on smaller screens for better touch targets */
    margin-bottom: 1rem; /* Space between items */
  }
}
