.video-section {
  position: relative; /* Positioned relative to place child elements */
  height: 90vh; /* Full height on desktop */
  width: 100%; /* Full width */
  overflow: hidden; /* Hide overflow */
}

.video-bg {
  width: 100%; /* Full width for video */
  height: 100%; /* Full height */
  object-fit: cover; /* Cover the area */
}

.video-overlay {
  position: absolute; /* Positioned absolutely to cover the video */
  top: 0; /* Top aligned */
  left: 0; /* Left aligned */
  height: 100%; /* Full height */
  width: 100%; /* Full width */
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Vertical alignment */
  justify-content: center; /* Centering content */
  align-items: center; /* Centering content */
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  
  text-align: center; /* Centered text */
  z-index: 5; /* Stays above video */
  padding: 2rem; /* Add padding for spacing */
}

.company-title {
  font-size: 4rem; /* Company title size */
  margin-bottom: 0.5rem; /* Spacing below title */
  color: white; /* Title color */
}

.slogan {
  font-size: 1.5rem; /* Slogan size */
  margin-bottom: 1rem; /* Spacing below slogan */
  color: white; /* Slogan color */
}

.subtitle {
  font-size: 1.8rem; /* Subtitle size */
  margin-top: 2rem; /* Spacing above subtitle */
  margin-bottom: 1rem; /* Spacing below subtitle */
  color: white; /* Subtitle color */
}

.description {
  font-size: 1rem; /* Description size */
  margin-bottom: 2rem; /* Spacing below description */
  color: white; /* Description color */
}

.contact-button {
  background-color: #007bff; /* Blue button color */
  border: none; /* No border */
  padding: 1rem 2rem; /* Button padding */
  color: white; /* Button text color */
  font-size: 1rem; /* Button text size */
  cursor: pointer; /* Pointer cursor */
  margin-top: 0.5rem; /* Spacing above button */
  border-radius: 25px; /* Rounded corners */
}

.contact-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.banners-container {
  position: relative; /* Changed to relative for normal document flow */
  width: 100%; /* Full width */
  display: flex; /* Flexbox for layout */
  justify-content: center; /* Center the banners */
  margin-top: 2rem; /* Add margin on top to separate from overlay */
}

.banner {
  background-color: rgba(255, 255, 255, 0.0001); /* Transparent background */
  padding: 1rem; /* Inner padding */
  margin: 0 0.5rem; /* Spacing between banners */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center text */
  width: 30%; /* Default width for larger screens */
}

/* Responsive styles for mobile */
/* Existing CSS remains the same */

@media (max-width: 900px) {
  .video-section {
    overflow-y: scroll;
    height: 120vh; /* Reduced height for mobile */
    position: relative; /* Ensure content can overlay */
  }

  .company-title {
    font-size: 2rem; /* Company title size */
    margin-bottom: 0.5rem; /* Spacing below title */
    color: white; /* Title color */
  }

  .slogan {
    font-size: 1rem; /* Slogan size */
    margin-bottom: 1rem; /* Spacing below slogan */
    color: white; /* Slogan color */
  }
  
  .subtitle {
    font-size: 0.8rem; /* Subtitle size */
    margin-top: 1rem; /* Spacing above subtitle */
    margin-bottom: 1rem; /* Spacing below subtitle */
    color: white; /* Subtitle color */
  }
  
  .description {
    font-size: 0.6rem; /* Description size */
    margin-bottom: 1rem; /* Spacing below description */
    color: white; /* Description color */
  }
  
  .contact-button {
    background-color: #007bff; /* Blue button color */
    border: none; /* No border */
    padding: 1rem 2rem; /* Button padding */
    color: white; /* Button text color */
    font-size: 1rem; /* Button text size */
    cursor: pointer; /* Pointer cursor */
    margin-top: 0.5rem; /* Spacing above button */
    border-radius: 25px; /* Rounded corners */
  }

  .banners-container {
    position: static; /* Change from absolute to static */
    margin-top: 1rem; /* Space between video and banners */
  }
  
  /* Ensure overlay content is still positioned above the video */
  .video-overlay {
    position: absolute; /* Maintain overlay position */
    top: 0; /* Top aligned */
    left: 0; /* Left aligned */
    right: 0; /* Full width */
    padding: 1rem; /* Adjust padding for mobile */
  }
}
