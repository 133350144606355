/* General container for the About Us page */
.about-us-container {
  font-family: 'Roboto', sans-serif;
}

/* Section-specific styling */
.about-us-section,
.differentiator-section,
.stats-section,
.testimonial-section,
.mission-section,
.contact-section {
  padding: 3rem;
  text-align: center;
}

.about-us-section {
  background-color: #f0f8ff;
}

.differentiator-section {
  background-color: #e6f7ff;
}

.stats-section {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.testimonial-section {
  background-color: #fff8e6;
  font-style: italic;
}

.mission-section {
  background-color: #f2f2f2;
}

.contact-section {
  background-color: #e6f2ff;
  padding: 2rem;
}

/* Typography and Text */
.intro-text {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}

.differentiator-section h2,
.testimonial-section h2,
.mission-section h2,
.stats-section h2 {
  color: #007bff;
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Differentiator Cards */
.differentiator-cards {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.differentiator-card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 2rem;
  width: 22%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.differentiator-card:hover {
  transform: translateY(-5px);
}

.differentiator-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.differentiator-card p {
  font-size: 1rem;
  color: #555;
}

/* Stats */
.stat-item h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #007bff;
}

.stat-item p {
  font-size: 1.2rem;
  color: #666;
}

/* Button Styling */
.contact-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #0056b3;
}

.chatWithUs-button {
  background-color: #25D366; /* WhatsApp Green */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Cursor change on hover */
  transition: background-color 0.3s; /* Smooth transition */
  margin-top: 1rem; /* Space above the button */
}

.chatWithUs-button:hover {
  background-color: #128C7E; /* Darker green on hover */
}


/* Mobile responsiveness */
@media (max-width: 768px) {
  .differentiator-cards {
    flex-direction: column; /* Stack cards vertically */
    width: 100%;
  }

  .differentiator-card {
    width: 90%; /* Expand card width on mobile */
    margin-bottom: 1rem; /* Add space between cards */
  }

  .stats-section {
    flex-direction: column; /* Stack stats items */
  }

  .stat-item {
    margin-bottom: 1.5rem; /* Add space between stats */
  }

  .about-us-section,
  .differentiator-section,
  .stats-section,
  .testimonial-section,
  .mission-section,
  .contact-section {
    padding: 2rem; /* Reduce padding for smaller screens */
  }
}

/* Smaller mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .intro-text {
    font-size: 1rem; /* Reduce font size for smaller devices */
  }

  .differentiator-card {
    padding: 1.5rem; /* Reduce padding */
  }

  .stat-item h2 {
    font-size: 2rem; /* Adjust stat heading size */
  }

  .contact-button {
    font-size: 1rem; /* Smaller button for small screens */
    padding: 0.5rem 1.5rem;
  }
}
