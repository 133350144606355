.services-section {
  background-color: #2d62d61a; /* Very light grey background */
  padding: 4rem 2rem;
  color: #333;
  text-align: center;
}

.services-description {
  max-width: 800px; /* Maximum width for larger screens */
  margin: 0 auto; /* Center the description */
  padding: 2rem;
}

.services-description h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.services-description h2 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.services-description p,
.services-description ul {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.services-description ul {
  list-style-type: disc;
  margin-left: 2rem; /* Space before list items */
  text-align: left;
}

.services-banners {
  display: flex; /* Flexbox for layout */
  justify-content: space-around;
  margin-top: 3rem;
  gap: 1.5rem; /* Space between banners */
}

/* Service banner styling */
.service-banner {
  background-color: rgba(255, 255, 255, 0.5); /* Transparent banners */
  padding: 2rem;
  border-radius: 12px; /* Rounded corners */
  width: 30%; /* Default width for larger screens */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition effects */
  text-align: left; /* Align text to the left */
  margin-bottom: 4rem; /* Space below banners */
}

.service-banner h3 {
  font-size: 1.8rem; /* Heading size */
  margin-bottom: 1rem; /* Space below heading */
}

.service-banner p {
  font-size: 1.1rem; /* Paragraph size */
  line-height: 1.5; /* Line height */
}

.service-banner:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

/* Footer styling */
footer {
  background-color: #333; /* Dark footer background */
  color: white; /* White text */
  padding: 1rem; /* Padding */
  text-align: center; /* Center footer text */
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-banners {
      flex-direction: column; /* Stack banners vertically */
      align-items: center; /* Center align banners */
  }

  .service-banner {
      width: 80%; /* Wider width for mobile */
      margin-bottom: 2rem; /* Space between stacked banners */
  }

  .services-description h1 {
      font-size: 2rem; /* Smaller heading size for mobile */
  }

  .services-description h2 {
      font-size: 1.5rem; /* Smaller subheading size for mobile */
  }

  .services-description p,
  .services-description ul {
      font-size: 1rem; /* Smaller text size for mobile */
  }

  .services-description ul {
      margin-left: 1.5rem; /* Reduce margin for mobile */
  }
}
