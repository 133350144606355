.navbar {
  padding: 1rem; /* Padding for spacing */
  text-align: center; /* Center text alignment */
 
  
}

.navbar-list {
  list-style: none; /* Remove default list styling */
  display: flex; /* Flexbox for horizontal alignment */
  justify-content: center; /* Center items */
  gap: 2rem; /* Space between items */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  flex-wrap: wrap; /* Allow items to wrap if necessary */
}

.navbar-list li {
  color: black; /* Text color */
  font-size: 1.2rem; /* Font size for navbar items */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative; /* Required for the underline effect */
  padding-bottom: 0.2rem; /* Space below the text */
  transition: color 0.3s ease; /* Smooth color transition */
}

.navbar-list li:hover {
  color: #007bff; /* Change text color on hover */
}

/* Underline effect on hover */
.navbar-list li::after {
  content: ''; /* Create pseudo-element for underline */
  position: absolute; /* Position relative to the parent */
  width: 0; /* Initial width */
  height: 2px; /* Height of underline */
  background-color: #007bff; /* Color of underline */
  bottom: 0; /* Position at the bottom */
  left: 50%; /* Center the underline */
  transition: width 0.3s ease, left 0.3s ease; /* Smooth transition */
}

.navbar-list li:hover::after {
  width: 100%; /* Full width on hover */
  left: 0; /* Align to left on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-list {
    flex-direction: row; /* Keep items in a row on mobile */
    justify-content: space-around; /* Distribute space around items */
    padding: 0 1rem;
    gap: 1rem; /* Add padding for better spacing */
  }

  .navbar-list li {
    font-size: 0.9rem; /* Smaller font size on mobile */
    padding: 0.1rem; /* Adjust padding for better tap targets */
  }
}
