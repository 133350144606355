/* Full-width layout */
.contact-us-page {
  width: 100vw; /* Full viewport width */
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
}

/* Ensure the forms-container takes full width */
.forms-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  max-width: 80vw; /* Restrict to a reasonable width on large screens */
  margin: 0 auto;
}

/* Form Styling */
.form {
  background-color: white;
  padding: 3rem; /* Larger padding for form */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Increase form width */
  margin-bottom: 4rem;
}

.student-form {
  background-color: #ffe6e6; /* Light pink for student form */
}

.tutor-form {
  background-color: #f3e6ff; /* Light purple for tutor form */
}

/* Input container for floating label effect */
.input-container {
  position: relative;
  margin-bottom: 1.5rem;
}

.contact-us-text {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.call-us-text {
  margin-bottom: 3rem;
  font-size: 0.6rem;
  color: #660bbb;
}

/* Contact Button Styling */
.contact-button {
  background-color: #007bff; /* Blue color */
  border: none;
  padding: 1rem 2rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 0.5rem;
  border-radius: 25px; /* Rounded corners */
  margin-bottom: 4rem;
}

.contact-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Input and Textarea Styling */
input, textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
  outline: none;
  transition: 0.3s;
}

label {
  position: absolute;
  left: 15px;
  top: 22px;
  background-color: white;
  padding: 0 5px;
  color: #aaa;
  font-size: 1.2rem;
  transition: 0.3s;
  pointer-events: none;
}

/* Move label to top when input is focused or has content */
input:focus ~ label,
textarea:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:not(:placeholder-shown) ~ label,
input:valid ~ label,
textarea:valid ~ label {
  top: -12px;
  left: 12px;
  font-size: 0.9rem;
  color: #007bff;
}

input:focus, textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button Styling */
.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem; /* Larger button text */
  cursor: pointer;
  border-radius: 20px; /* Rounded corners */
  width: 40%; /* Wider button */
}

.submit-button:hover {
  background-color: #0056b3;
}

h2 {
  font-size: 2rem; /* Larger headings */
  margin-bottom: 2rem;
}

textarea {
  resize: vertical;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .forms-container {
    flex-direction: column; /* Stack forms vertically on smaller screens */
    gap: 1rem; /* Reduce gap between forms */
  }

  .form {
    width: 100%; /* Form takes full width on small screens */
  }

  .submit-button {
    width: 60%; /* Adjust button width for mobile */
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem; /* Smaller headings for mobile */
  }

  .submit-button {
    width: 80%; /* Increase button width for very small screens */
  }

  .contact-button {
    width: 80%; /* Adjust contact button size for mobile */
  }
}
