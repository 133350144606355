body {
  margin: 0; /* Remove default margin */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* Font stack for body text */
  -webkit-font-smoothing: antialiased; /* Smooth font rendering */
  -moz-osx-font-smoothing: grayscale; /* Smooth font rendering for Mac */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; /* Font stack for code */
}

/* Responsive Styles for Mobile Compatibility */
@media (max-width: 768px) {
  body {
    padding: 0 1rem; /* Add padding for small screens */
  }

  code {
    font-size: 0.9rem; /* Slightly reduce font size for code */
  }
}
