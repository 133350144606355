.client-feedback-section {
  background-color: #f8f8f8;
  padding: 2rem;
  text-align: center;
  width: 100%; /* Full width */
  margin: 0 auto; /* Center the section horizontally */
  overflow: hidden; /* Hide any overflow content */
}

.client-feedback-section h6 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.feedback-list-wrapper {
  width: 100%;
  overflow: hidden; /* Hide the horizontal scrollbar */
  position: relative; /* Set relative positioning */
}

.feedback-list {
  display: flex;
  width: calc(350px * 8); /* Adjust width to fit more items in the scroll */
  animation: scroll 30s linear infinite; /* Infinite scrolling animation */
}

.feedback-item {
  background-color: #f6cefc2d;
  padding: 1rem;
  width: 350px; /* Width of individual feedback items */
  height: auto; /* Adjust height based on content */
  border-radius: 12px; /* Slight curve at corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  white-space: normal; /* Ensure text wraps within the box */
  word-wrap: break-word; /* Handle long words */
  transition: transform 0.8s ease, box-shadow 0.8s ease;
}

.feedback-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.feedback-item h4 {
  margin-bottom: 0.5rem;
  color: #9000ff88;
}

.rating {
  font-size: 1.2rem;
  color: #ffc107;
  margin-bottom: 1rem;
}

.feedback-item p {
  font-size: 0.9rem;
  color: #555;
  margin: 0; /* Remove default margin */
  line-height: 1.5; /* Improve readability */
}

/* Infinite scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Scroll all feedback items */
  }
}

.feedback-list:hover {
  animation-play-state: paused;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .feedback-item {
    width: 200px; /* Reduce width for mobile to show more feedbacks */
  }

  .client-feedback-section h6 {
    font-size: 1.5rem; /* Reduce heading font size */
  }

  .feedback-list {
    animation: scroll 15s linear infinite; /* Increased speed for mobile */
    width: calc(300px * 20);
  }

  /* Ensure feedback items can fit in a wide enough row */
  .feedback-list-wrapper {
    width: 100%;
  }
}

/* Additional Media Queries for Desktop Responsiveness */
@media (min-width: 1200px) {
  .client-feedback-section {
    max-width: 1600px; /* Maintain a maximum width for larger screens */
  }

  .feedback-list {
    width: calc(350px * 16); /* Adjust width for all feedback items on large screens */
  }

  .feedback-list {
    animation: scroll 30s linear infinite; /* Slower scroll speed on desktop */
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .client-feedback-section {
    max-width: 900px; /* Adjust maximum width for medium desktops */
  }

  .feedback-list {
    width: calc(350px * 12); /* Adjust width for all feedback items */
  }

  .feedback-list {
    animation: scroll 30s linear infinite; /* Slower scroll speed on desktop */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .client-feedback-section {
    max-width: 700px; /* Adjust for tablets */
  }

  .feedback-list {
    width: calc(350px * 10); /* Adjust width for all feedback items */
  }

  .feedback-list {
    animation: scroll 30s linear infinite; /* Slower scroll speed on tablets */
  }
}
