* {
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

html, body {
  height: 100%; /* Full height for html and body */
  font-family: Arial, sans-serif; /* Font family */
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Text color */
}

.App {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  min-height: 100vh; /* Minimum height for full viewport */
}

/* Responsive styles */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Adjust base font size for mobile */
  }

  .App {
    padding: 1rem; /* Add padding to App container for mobile */
    justify-content: flex-start; /* Align items to the top */
  }
}

/* Additional mobile-specific styles can be added here */
