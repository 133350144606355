.combined-section {
  background: linear-gradient(to bottom, #FFB6C1, #ffb6c1a4); /* Light purple gradient */
  padding: 4rem 2rem;
  color: white;
  text-align: center;
}

.section-title h6 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: black; /* Slightly darker text for better contrast */
}

.solutions-banners, .steps {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.solution-banner, .step {
  background-color: rgba(0, 0, 0, 0.336); /* Increased transparency for a unified look */
  padding: 2rem;
  width: 30%;
  text-align: center;
  border-radius: 12px; /* Slightly more rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for a modern look */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.solution-banner:hover, .step:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2); /* Slight lift and deeper shadow on hover */
}

.solution-banner h3, .step h4 {
  margin-bottom: 1rem;
  color: #fff;
}

.solution-banner p, .step p {
  font-size: 1rem;
  color: #f0f0f0;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .solutions-banners, .steps {
      flex-direction: column; /* Stack banners vertically on smaller screens */
      align-items: center;
  }

  .solution-banner, .step {
      width: 80%; /* Increase width to take up more screen space on smaller devices */
      margin-bottom: 2rem;
  }

  .section-title h6 {
      font-size: 1.5rem; /* Reduce title font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .solution-banner, .step {
      width: 90%; /* Further adjust for very small devices */
  }

  .section-title h6 {
      font-size: 1.2rem; /* Reduce title font size even further */
  }
}
